import React from "react";
import uuidv4 from "uuid/v4";

import { ApiClient, Site } from "./ApiClient";

interface LinkAccountProps
{
    apiClient: ApiClient;
}

interface LinkAccountState
{
    email: string;
    sites: Site[];
}

export class LinkAccount extends React.Component<LinkAccountProps, LinkAccountState>
{
    constructor(props: LinkAccountProps)
    {
        super(props);

        this.state = {
            email: "",
            sites: []
        };
    }

    render()
    {
        return (
            <div className="link-account">
                <div className="email-input">
                    <div>Find your Panopto site:</div>
                    <input onChange={this.handleEmailLookup} name="email" type="text" placeholder="Type your Panopto email address" value={this.state.email} />
                </div>

                {
                    this.state.sites.length > 0 &&
                    <div className="site-list">
                        <ul>
                            {
                                this.state.sites.map((site) => (
                                    <li key={site.SiteId} onClick={() => this.handleSave(site.SiteUrl)}>{site.FriendlySiteName} ({site.SiteUrl})</li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
        );
    }

    private handleEmailLookup = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        const email = e.target.value;

        this.props.apiClient.getSitesWithDebounce(email).then((sites) =>
        {
            this.setState({ sites });
        });

        this.setState({ email });
    }

    private handleSave = (siteUrl: string) =>
    {
        // TODO: this token should be received from panopto-core
        const panoptoAccessToken = uuidv4();
        const siteFqdn = siteUrl.replace("https://", "");

        this.props.apiClient.mapTokenToFqdn(panoptoAccessToken, siteFqdn).then((alexaAccessToken) =>
        {
            this.redirectToAlexa(alexaAccessToken);
        });
    }

    private redirectToAlexa(alexaAccessToken: string)
    {
        const inputParams = new URLSearchParams(window.location.search);

        const outputParams = new URLSearchParams();
        outputParams.set("access_token", alexaAccessToken);
        outputParams.set("token_type", "Bearer");
        outputParams.set("state", inputParams.get("state")!);

        const redirectUrl = new URL(inputParams.get("redirect_uri")!);
        redirectUrl.hash = outputParams.toString();

        window.location.href = redirectUrl.toString();
    }
}