import "./App.css";

import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { ApiClient } from "./ApiClient";
import { Home } from "./Home";
import { LinkAccount } from "./LinkAccount";

const apiClient = new ApiClient({
    findMyPanoptoServiceUrl: process.env.REACT_APP_FMP_SERVICE_URL!,
    alexaServiceUrl: process.env.REACT_APP_ALEXA_SERVICE_URL!,
});

const App: React.FC = () =>
{
    return (
        <div className="app">
            <BrowserRouter>
                <Route path="/" exact={true} component={Home}>
                </Route>
                <Route path="/link-account" render={() => (<LinkAccount apiClient={apiClient} />)} />
            </BrowserRouter>
        </div>
    );
}

export default App;
