import axios from "axios";

interface ApiClientOptions
{
    findMyPanoptoServiceUrl: string;
    alexaServiceUrl: string;
}

interface FindMyPanoptoResponse
{
    data:
    {
        sites: Site[]
    }
}

export interface Site
{
    SiteUrl: string;
    SiteId: string;
    FriendlySiteName: string;
}

interface MapTokenToFqdnResponse
{
    data: string;
}

export class ApiClient
{
    private debounceTimeout?: number;

    constructor(private readonly options: ApiClientOptions)
    {
    }

    getSitesWithDebounce(email: string, debounceDelay = 200): Promise<Site[]>
    {
        if (this.debounceTimeout !== undefined)
        {
            clearTimeout(this.debounceTimeout);
        }

        return new Promise((resolve, reject) =>
        {
            this.debounceTimeout = window.setTimeout(() => 
            {
                this.getSites(email)
                    .then((sites) => { resolve(sites); })
                    .catch(() => { reject() });
            }, debounceDelay);
        });
    }

    getSites(email: string): Promise<Site[]>
    {
        const requestUrl = new URL(this.options.findMyPanoptoServiceUrl);
        requestUrl.searchParams.set("email", email);

        return axios.get<FindMyPanoptoResponse>(requestUrl.toString()).then((response) =>
        {
            return response.data.data.sites;
        });
    }

    mapTokenToFqdn(panoptoAccessToken: string, siteUrl: string): Promise<string>
    {
        const requestUrl = new URL(`${this.options.alexaServiceUrl}/alexa/maptokentofqdn`);
        const requestData = {
            "Token": panoptoAccessToken,
            "FQDN": siteUrl
        };

        return axios.post<MapTokenToFqdnResponse>(requestUrl.toString(), requestData, {
            headers: {
                "Content-Type": "text/plain"
            }
        }).then((response) =>
        {
            console.debug(response);
            return response.data.data;
        });
    }
}